const COLORS = {
  blue: '#2E86AB',
  lavender: '#AA9ABA',
  black: '#262626',
  green: '#79B791',
  red: '#CC444B',
  white: '#efefef',
  gray: '#817f83',
  offwhite: '#fbfbfb',
  darkblue: '#052F5F',
};

export default COLORS;
