import { MDXProvider } from '@mdx-js/react';
import React from 'react';

import { CodeBlock } from './src/components/CodeBlock';
import { InlineCode } from './src/elements/InlineCode';
import COLORS from './src/utils/colors';

const components = {
  a: (props) => (
    <a style={{ textDecoration: 'none', color: COLORS.blue }} target="_blank" {...props} />
  ),
  inlineCode: (props) => <InlineCode {...props} />,
  pre: ({ children: { props } }) => {
    if (props.mdxType === 'code') {
      return (
        <CodeBlock
          codeString={props.children.trim()}
          language={props.className && props.className.replace('language-', '')}
          {...props}
        />
      );
    }
  },
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
