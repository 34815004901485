import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwlLight';
import React from 'react';
import styled from 'styled-components';
import rangeParser from 'parse-numeric-range';

/**
 * Create a closure to indicate if a given line should be highlighted
 * @param {t} meta must be a combination of '-', ',' and integers
 */
const shouldHighlightLine = (meta) => {
  const regexr = /{([\d,-]+)}/;

  if (regexr.test(meta)) {
    // Pass in the lines to highlight as {1,2-4,5} at the top of the code block
    const strLineNumbers = regexr.exec(meta)[1];
    const lineNumbers = rangeParser(strLineNumbers);

    return (index) => lineNumbers.includes(index + 1);
  } else {
    return () => false;
  }
};

// https://mdxjs.com/guides/syntax-highlighting#all-together
export const CodeBlock = ({ codeString, language, metastring }) => {
  const isHighlighted = shouldHighlightLine(metastring);
  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Pre className={className} style={{ ...style }}>
          {tokens.map((line, index) => {
            const lineProps = getLineProps({ line, key: index });
            if (isHighlighted(index)) {
              lineProps.className = `${lineProps.className} highlight-line`;
            }
            return (
              <div key={index} {...lineProps}>
                <LineNo>{index + 1}</LineNo>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            );
          })}
        </Pre>
      )}
    </Highlight>
  );
};
// Styles

const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  overflow-x: auto;
  border-radius: 3px;
  font-size: 1em;

  & .token-line {
    line-height: 1.4em;
    height: 1.3em;
  }
  font-family: 'Roboto Mono', monospace;

  & .highlight-line {
    background-color: rgb(225, 228, 239);
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.3em solid #f99;
  }
`;

const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`;

// deprecated for now
/*
const CopyCodeButton = styled.div`
  float: right;
  border: 0;
  border-radius: 3px;
  margin: 0.25em;
  opacity: 0.3;
  padding: 0.3em auto;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
*/
